import { Link } from 'gatsby';
import styled from 'styled-components';
import FONTS from '@constants/fonts';
import SCREENS from '@constants/screens';
var headerHeight = '4rem';
var StyledHeader = styled.header.withConfig({
    displayName: "headerstyled__StyledHeader",
    componentId: "sc-9rgc4r-0"
})(["padding:1.125rem 0;display:flex;align-items:center;width:100%;min-height:", ";max-height:max-content;background-color:var(--black);border-bottom:1px solid var(--gray);position:fixed;top:0;z-index:1;"], headerHeight);
var Content = styled.div.withConfig({
    displayName: "headerstyled__Content",
    componentId: "sc-9rgc4r-1"
})(["display:flex;justify-content:space-between;align-items:center;width:100%;color:var(--white);"]);
var StyledUl = styled.nav.withConfig({
    displayName: "headerstyled__StyledUl",
    componentId: "sc-9rgc4r-2"
})(["display:flex;@media only screen and (max-width:", "){display:none;}"], SCREENS.md);
var StyledLi = styled.li.withConfig({
    displayName: "headerstyled__StyledLi",
    componentId: "sc-9rgc4r-3"
})(["margin:0 0 0 2rem;list-style-type:none;"]);
var StyledLink = styled(Link).withConfig({
    displayName: "headerstyled__StyledLink",
    componentId: "sc-9rgc4r-4"
})(["text-decoration:none;color:inherit;font:", ";font-size:1rem;color:var(--muted);&:hover{color:var(--primary);}"], FONTS.foundersGroteskRegular);
var Logo = styled.img.withConfig({
    displayName: "headerstyled__Logo",
    componentId: "sc-9rgc4r-5"
})(["max-height:1.75rem;"]);
export { StyledHeader, Content, StyledUl, StyledLi, StyledLink, headerHeight, Logo };

import styled from 'styled-components';
import Screens from '@constants/screens';
var footerHeight = '4.5rem';
var StyledFooter = styled.footer.withConfig({
    displayName: "footerstyled__StyledFooter",
    componentId: "mrft9s-0"
})(["display:flex;align-items:center;width:100%;height:", ";background-color:var(--black);border-top:1px solid var(--gray);"], footerHeight);
var Content = styled.div.withConfig({
    displayName: "footerstyled__Content",
    componentId: "mrft9s-1"
})(["display:flex;justify-content:space-between;align-items:center;width:100%;color:var(--white);font-size:0.8125rem;flex-direction:row;@media (min-width:", "){font-size:0.875rem;}"], Screens.sm);
var Legal = styled.span.withConfig({
    displayName: "footerstyled__Legal",
    componentId: "mrft9s-2"
})([""]);
var Attribution = styled.span.withConfig({
    displayName: "footerstyled__Attribution",
    componentId: "mrft9s-3"
})([""]);
export { StyledFooter, Content, Legal, Attribution, footerHeight };

import _taggedTemplateLiteralLoose from "@babel/runtime/helpers/esm/taggedTemplateLiteralLoose";
var _templateObject;
import { createGlobalStyle } from 'styled-components';
import FONTS from '@constants/fonts';
var GlobalStyle = createGlobalStyle(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n\n  :root {\n    --muted: ", ";\n    --black: ", ";\n    --white: ", ";\n    --dark: ", ";\n    --primary: ", ";\n    --gray: ", ";\n    --buttonBorderRadius: ", ";\n    --colorBorderRadius: ", ";\n  }\n\n  body {\n    margin: 0;\n    min-height: 100vh;\n    font: ", ";\n    line-height: 1.5;\n  }\n\n  *, *:before, *:after {\n    box-sizing: border-box;\n  }\n\n  ::selection {\n    color: var(--dark);\n    background: rgba(255, 255, 255, 0.9);\n  }};\n  }\n\n  p {\n    margin: 0;\n  }\n"])), function (_ref) {
    var stylesProps = _ref.stylesProps;
    return stylesProps.muted;
}, function (_ref2) {
    var stylesProps = _ref2.stylesProps;
    return stylesProps.black;
}, function (_ref3) {
    var stylesProps = _ref3.stylesProps;
    return stylesProps.white;
}, function (_ref4) {
    var stylesProps = _ref4.stylesProps;
    return stylesProps.dark;
}, function (_ref5) {
    var stylesProps = _ref5.stylesProps;
    return stylesProps.primary;
}, function (_ref6) {
    var stylesProps = _ref6.stylesProps;
    return stylesProps.gray;
}, function (_ref7) {
    var _stylesProps$button_b;
    var stylesProps = _ref7.stylesProps;
    var radius = (_stylesProps$button_b = stylesProps.button_border_radius) === null || _stylesProps$button_b === void 0 ? void 0 : _stylesProps$button_b.text;
    return radius && radius.match(/rem|px/g) ? radius : (radius || 4) + "px";
}, function (_ref8) {
    var _stylesProps$color_bl;
    var stylesProps = _ref8.stylesProps;
    var radius = (_stylesProps$color_bl = stylesProps.color_block_border_radius) === null || _stylesProps$color_bl === void 0 ? void 0 : _stylesProps$color_bl.text;
    return radius && radius.match(/rem|px/g) ? radius : (radius || 0) + "px";
}, FONTS.foundersGroteskRegular);
export default GlobalStyle;

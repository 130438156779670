import { Link } from 'gatsby';
import styled from 'styled-components';
import FONTS from '@constants/fonts';
import Screens from '@constants/screens';
var StyledTextLink = styled(Link).withConfig({
    displayName: "linkstyled__StyledTextLink",
    componentId: "sc-1a6ogfv-0"
})(["color:var(--primary);font:", ";font-size:0.875rem;:hover{color:var(--white);}"], FONTS.foundersGroteskRegular);
var StyledButtonLink = styled(Link).withConfig({
    displayName: "linkstyled__StyledButtonLink",
    componentId: "sc-1a6ogfv-1"
})(["padding:0 2.5rem;display:flex;justify-content:center;align-items:center;width:max-content;background-color:transparent;border-radius:var(--buttonBorderRadius);border:1px solid var(--white);color:var(--white);height:3.75rem;font:", ";line-height:1;text-decoration:none;:hover{background-color:var(--primary);border-color:var(--primary);color:var(--black);}@media (max-width:", "){font-size:1rem;padding:0 2.25rem;height:3.5rem;}"], FONTS.foundersGroteskRegular, Screens.sm);
var StyledButtonLabel = styled.span.withConfig({
    displayName: "linkstyled__StyledButtonLabel",
    componentId: "sc-1a6ogfv-2"
})(["line-height:1rem;"]);
export { StyledTextLink, StyledButtonLink, StyledButtonLabel };

import styled from 'styled-components';
import { headerHeight } from '@components/header/header.styled';
import { footerHeight } from '@components/footer/footer.styled';
var Container = styled.div.withConfig({
    displayName: "default-layoutstyled__Container",
    componentId: "sc-1e1dt71-0"
})(["min-height:100vh;padding-top:4rem;overflow:hidden;"]);
var Content = styled.div.withConfig({
    displayName: "default-layoutstyled__Content",
    componentId: "sc-1e1dt71-1"
})(["min-height:calc(100vh - ", " - ", ");background-color:var(--black);color:var(--white);"], headerHeight, footerHeight);
export { Container, Content };

import styled from 'styled-components';
import SCREENS from '@constants/screens';
var BurgerButton = styled.div.withConfig({
    displayName: "mobile-headerstyled__BurgerButton",
    componentId: "sc-1l4uxso-0"
})(["z-index:25;position:relative;display:none;background:transparent;width:1.5rem;height:0.875rem;cursor:pointer;&:before,&:after{content:'';left:0;background:var(--white);width:1.5rem;height:2px;border-radius:1.25rem;transition:0.75s;backface-visibility:hidden;}&:before{box-shadow:var(--white) 0 6px 0 0;position:absolute;top:0;}&:after{position:absolute;top:calc(100% - 2px);}", ";@media only screen and (max-width:", "){display:block;}"], function (_ref) {
    var enabled = _ref.enabled;
    return enabled && "\n      &:before {\n        box-shadow: transparent 0 0 0 0;\n        top: 50%;\n        transform: rotate(225deg);\n      }\n  \n      &:after {\n        top: 50%;\n        transform: rotate(315deg);\n      }\n    ";
}, SCREENS.md);
var heightLi = 1;
var MobileMenu = styled.ul.withConfig({
    displayName: "mobile-headerstyled__MobileMenu",
    componentId: "sc-1l4uxso-1"
})(["padding:0;margin:0;height:", "rem;opacity:1;color:var(--white);transition:height 0.3s;li{margin:", "rem 0 0;height:", "rem;}", ";@media only screen and (min-width:", "){display:none;}"], function (_ref2) {
    var itemsCount = _ref2.itemsCount;
    return itemsCount * (heightLi * 2) - heightLi;
}, heightLi, heightLi, function (_ref3) {
    var enabled = _ref3.enabled;
    return enabled && "\n    height: 0;\n    opacity: 0;\n\n    li {\n      margin: 0;\n    }\n  ";
}, SCREENS.md);
export { BurgerButton, MobileMenu };
